import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.jsx";
import Body from '../../components/Body';
import Columns from '../../components/Columns';
import Donate from '../../components/Donate';
import Hero from '../../components/Hero';
import Shop from '../../components/Shop';
import HeroFooter from '../../components/HeroFooter';
import ImageInline from '../../components/ImageInline';
import ImageGrid from '../../components/ImageGrid';
import Headline from '../../components/Body/Headline';
import Intro from '../../components/Intro';
import Quote from '../../components/Quote';
import LogoGrid from '../../components/LogoGrid';
import SARMessage from '../../components/SARMessage';
import Twitter from '../../components/Twitter';
import Video from '../../components/Video';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Shop mdxType="Shop" />
    <Hero mdxType="Hero" />
    <Intro excerpt="This is the M.V. Louise Michel A high-speed lifeboat that patrols the Mediterranean" mdxType="Intro">
    </Intro>
    <Columns firstCol={<Body mdxType="Body">
      <p>{`  The MV Louise Michel is a former French Navy boat we’ve customized to perform search and rescue. Measuring 30 meters in length and capable of up to 28 knots, she was bought with proceeds from the sale of Banksy artwork - who then decorated her with a fire extinguisher. She is operated and crewed by a team of international activist rescue professionals. Named after the French anarchist Louise Michel, she aims to combine sea rescue with the `}<strong parentName="p">{`principles of queer-feminism, anti-racism and anti-fascism`}</strong>{`. She runs on a flat hierarchy and a vegan diet.`}</p>
      <p>{`  It might seem incredible there is a need for a private emergency vessel in one of Europe’s busiest waterways, but there is. In response to people trying to cross the Mediterranean in search of safety, European states stopped reacting properly to distress calls from people on the move, leaving them to drift at sea and collaborating with armed forces to illegally prevent them from crossing. Europe’s Mediterranean border is the world’s deadliest.`}</p>
    </Body>} secondCol={<Twitter title="Live Feed" label="Follow @MVLouiseMichel" mdxType="Twitter" />} mdxType="Columns" />
    <ImageGrid images={[["img1.jpg"], ["img2.jpg", "img3.jpg"]]} mdxType="ImageGrid" />
    <Body dark mdxType="Body">
  <Headline level="2" standalone mdxType="Headline">Our Goals</Headline>
      <p>{`  The MV Louise Michel and our team of activists and volunteers actively resist the discriminatory power structures of nationalism, racism, patriarchy and capitalism. The MV Louise Michel intends to uphold maritime law by rescuing anyone in peril without prejudice. In solidarity with people on the move, we are working against any policy that willingly lets people drown at sea. We are fighting for freedom of movement for everyone, because as Louise Michel said, `}<strong parentName="p">{`“Something besides charity is needed in order to provide bread for everyone.”`}</strong>{` Humanitarian aid alone is not the answer.`}</p>
    </Body>
    <ImageInline image="video-cover.jpg" mdxType="ImageInline" />
    <Body dark home mdxType="Body">
  <Headline level="2" standalone mdxType="Headline">How to support</Headline>
      <ul>
        <li parentName="ul">{`Donate to provide funding for this work, tackle bureaucratic hurdles and fight legal cases.`}</li>
        <li parentName="ul">{`Help spread the word about these inhumane policies. #FreedomOfMovementForAll`}</li>
        <li parentName="ul">{`Crew: if you align with our goals, please contact us via `}<a href="mailto:crewing@mvlouisemichel.org"><a parentName="li" {...{
              "href": "mailto:crewing@mvlouisemichel.org"
            }}>{`crewing@mvlouisemichel.org`}</a></a></li>
      </ul>
      <p>{`  The people who die at sea are not unlucky casualties of the elements. This situation is not a natural disaster. It is created by political decisions and a failure of humanity and solidarity. It cannot be solved by lifeboats. If we want to sustainably stop the drowning, we have to stop forcing people onto these boats. So, if you want to contribute:`}</p>
      <ul>
        <li parentName="ul">{`Speak up against racism.`}</li>
        <li parentName="ul">{`Join movements for decolonisation, climate justice and against capitalism.`}</li>
        <li parentName="ul">{`Take any kind of action to defend global respect for human rights and all forms of life, near or far.`}</li>
      </ul>
      <p>{`  How societies manage migration is a complex issue that involves us all. But it should never be one that casually dispenses the death penalty.`}</p>
  <SARMessage previous="Search and Rescue" now="Solidarity and Resistance" mdxType="SARMessage" />
    </Body>
    <LogoGrid mdxType="LogoGrid" />
    <HeroFooter mdxType="HeroFooter" />
    <Donate text="Donate" isVisible mdxType="Donate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      